<template>
  <div class="row">
    <div
      class="col-12 col-md-3 col-lg-2 noPrint"
      v-if="user && !user.isPatient"
    >
      <nav
        class="
          navbar navbar-vertical
          fixed-left
          navbar-expand-md navbar-light
          page-inner-nav-container
          nice-scroll
          z-100
        "
        style="position: relative; border-width: 0; background: none"
      >
        <ul class="navbar-nav border-none">
          <li
            class="nav-item"
            v-for="link in nav"
            :key="link.to || link.action"
          >
            <router-link
              v-if="!link.action"
              :to="getLink(link.to)"
              class="nav-link"
              :class="$route.name == link.to ? `active` : ``"
            >
              <icon :type="link.icon" class="mr-2" />
              {{ link.label }}
            </router-link>
            <a
              v-if="link.action"
              href="/"
              @click="openCalendarModal"
              class="nav-link"
            >
              <icon :type="link.icon" class="mr-2" />
              {{ link.label }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <div
      class="
        col-12 col-md-8 col-lg-9
        noPrint
        profile-section
        card
        shadow-card
        theme-card
      "
    >
      <router-view class="mt-4"></router-view>
    </div>
    <sidebar
      :toggle="modalOpened"
      @close="closeCalendarModal"
      :title="`${user.last}, ${user.first} Events`"
      wrapClass="calendar-tray"
      class="calendar-modal"
    >
      <Calendar
        :key="Math.random()"
        :profileAdminUserId="user.id"
        @closeModal="modalOpened = false"
        v-if="modalOpened"
      ></Calendar>
    </sidebar>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Calendar from "../../appointment/calendar";

export default {
  components: {
    Calendar,
  },
  data() {
    return {
      modalOpened: false,
      nav: [
        {
          label: "General",
          to: "admin.profile.basic",
          icon: "home",
        },
        {
          label: "Address",
          to: "admin.profile.address",
          icon: "map-signs",
        },
        {
          label: "Phones",
          to: "admin.profile.phones",
          icon: "phone",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    getLink(to) {
      return {
        name: to,
      };
    },
    openCalendarModal(event) {
      event.preventDefault();
      this.modalOpened = true;
    },
    closeCalendarModal() {
      this.modalOpened = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-section {
  padding-bottom: 20px;
  margin-left: 5px;
  padding-right: 10px;
}
</style>
